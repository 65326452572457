<template>
  <div class="container">

    <ValidationObserver
      v-slot="validation"
      tag="div"
    >
      <input-form :errors="validation.errors">

        <div class="columns is-mobile">
          <div class="column is-3">Bank name</div>
          <div class="column is-9">{{ bankName }}</div>
        </div>

        <div class="columns is-mobile">
          <div class="column is-3">Routing number</div>
          <div class="column is-9">{{ routingNumber }}</div>
        </div>

        <div class="columns">
          <div class="textbox-label-field column is-3">Last 4 digits of account number</div>
          <div class="column is-9">
            <div class="field">
              <vee-textbox
                v-model="accountNumber"
                label="Account number"
                name="Account number"
                placeholder="Enter the last four digits of the account number."
                rules="required"
                required
                @keyup="formatAccountNumber"
              />
              <!-- :rules="approveRules" -->
            </div>
          </div>
        </div>

        <div
          class="field is-grouped is-justified-center"
        >
          <div
            class="control margin-top-sm"
          >
          <!-- v-if="allowApprove" -->
            <button
              class="button is-primary"
              :class="{ 'is-loading': isLoading }"
              @click.prevent="submitForm(validation)"
            >
              Submit
            </button>
          </div>
        </div>

      </input-form>

    </ValidationObserver>
  </div>
</template>

<script>

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email, ext, numeric, digits } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "AchBankConfirmationForm",
  components: {
    VeeTextbox,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: null,
      accountNumber: null,
    };
  },
  computed: {
    bankName() {
      let value;
      if (this.$store.state.ach.achBank) {
        value = this.$store.state.ach.achBank.bankName;
      }
      return value;
    },
    routingNumber() {
      let value;
      if (this.$store.state.ach.achBank) {
        value = this.$store.state.ach.achBank.routingNumber;
      }
      return value;
    },
  },
  created() {
    extend('numeric', {
      ...numeric,
      message: 'The {_field_} field requires a number',
    });
    extend('digits', {
      ...digits,
      message: 'the {_field_} field has the wrong number of digits',
      // message: 'The value must be a five-digit number',
    });
  },
  methods: {
    formatAccountNumber() {
      let an = this.accountNumber.trim().split("").filter(c => {
        let regex = new RegExp(/\d/);
        return regex.test(c);
      });

      let number = '';
      for (let i=0; i<an.length;i++) {
        number += an[i] || '';
      }
      number = number.substring(0, 4);
      this.accountNumber = number;
    },
    async submitForm(validation) {
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }
      let query = {
        humanReadableId: this.achId,
        accountNumber: this.accountNumber,
      };
      const { data } = await this.$store.dispatch('ach/checkAchBank', query);
      if (data.matches) {
        this.$emit('right');
      } else {
        this.$emit('wrong');
      }
    },
  },
};

</script>
