<template>
  <div>
    <div
      :class="validationDiv"
    >
      <template v-if="errors[0]">
        <div class="input-error-msg">
          <span class="icon"><i class="fas fa-exclamation-circle" /></span>
          <span>{{ errors[0] }}</span>
        </div>
      </template>
      <input
        type="text"
        :value="value"
        disabled
      />
      <!-- readonly -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisabledTextField',
  props: [
    'value',
    'errors',
  ],
  // data: () => ({
  //   innerValue: '',
  // }),
  computed: {
    validationDiv() {
      let value;
      if (this.errors[0]) {
        value = 'is-error';
      }
      return value;
    },
  },
  watch: {
    value(newValue) {
      console.log('DisabledTextField watch value is firing, newValue:', newValue);
      this.$emit('changedValue');
    },
  },
};
</script>

<style lang="scss">

.is-error {
  background: hsl(0,92%,95.1%);
  padding: .5rem;
}

.input-error-msg {
  font-size: 0.9rem;
  color: hsl(12.9,100%,32.9%);
  font-weight: 600;
  .icon {
    font-size: 1rem;
  }
}

</style>
