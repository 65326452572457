<template>
  <div class="container">

    <overview :achId="achId" />
    <vendor-submitted />

    <ValidationObserver
      v-slot="validation"
      tag="div"
    >
      <input-form :errors="validation.errors">
        <div class="block">
          Complete the following steps to verify that this is a valid ACH request. Please verify the following information first.
        </div>

        <div class="field">
          <div
            class="top-align label radio-desc required"
          >
            Is this payee a vendor in your department?
          </div>
          <div class="control">
            <vee-radio
              v-model="inDepartment"
              :options="booleanOptions"
              numOfColumns="4"
              title="In Department"
              name="In Department"
              :rules="approveRules"
            />
          </div>
        </div>

        <div
          v-if="inDepartment === 'no'"
          class="block"
        >
          Select the correct department to transfer the request. If you're not sure, select "I do not know."
          <transfer-component
            @achTransferSuccess="reroute"
          />
        </div>

        <div v-if="inDepartment != 'no'">
          <div class="field">
            <div
              class="top-align label radio-desc required"
            >
              Do the vendor name and the payee name match?
            </div>
            <font-awesome-icon
              :id="'invoice-icon'"
              icon="info-circle"
              class="fa-infoCircle icon-margin"
              @click="infoClick('namesMatch')"
            />
            <div
              v-if="infoDivs.namesMatch"
              class="is-field-info-important field-info"
            >
              The payee name provided by the vendor should be a near or exact match for the vendor name. If you're not sure if they're close enough, deny the request and provide the reason.
            </div>

            <div class="control">
              <vee-radio
                v-model="namesMatch"
                :options="booleanOptions"
                numOfColumns="4"
                title="Names Match"
                name="Names Match"
                :rules="approveRules"
              />
            </div>
          </div>

          <div v-if="namesMatch != 'no'">

            <div class="field">
              <div
                class="top-align label radio-desc required"
              >
                Does the payee address match your department's records?
              </div>
              <div class="control">
                <vee-radio
                  v-model="matchesFAMIS"
                  :options="booleanOptions"
                  numOfColumns="4"
                  title="Matches FAMIS"
                  name="Matches FAMIS"
                  :rules="approveRules"
                />
              </div>
            </div>

            <div
              v-if="matchesFAMIS === 'no'"
              class="block"
            >
              If address does not match, contact <a href="mailto:voucherverification@phila.gov">voucherverification@phila.gov</a> for guidance before denying this request.
            </div>

            <div v-if="matchesFAMIS != 'no'">
              <hr>
              <div class="block">
                You must verify the rest of the information by phone. To be sure you're speaking with the vendor, use the contact phone number that is listed in FAMIS, ADPICS, ACIS, or your department records. Do not use this page as the source for the phone number.
              </div>

              <div class="field">
                <div class="top-align label radio-desc required">
                  Were you able to reach the vendor over the phone?
                </div>
                <div class="control">
                  <vee-radio
                    v-model="telephoneVendor"
                    :options="booleanOptions"
                    numOfColumns="4"
                    title="Able to Reach"
                    name="Able to Reach"
                    :rules="approveRules"
                  />
                </div>
              </div>

              <div
                v-if="telephoneVendor === 'no'"
                class="block"
              >
                Include a description of your attempts to contact the vendor in your reason for denial.
              </div>

              <div v-if="telephoneVendor != 'no'">
                <div class="field">
                  <div class="top-align label radio-desc required">
                    Is the employee listed above a current employee for the vendor?
                  </div>
                  <div class="control">
                    <vee-radio
                      v-model="currentEmployee"
                      :options="booleanOptions"
                      numOfColumns="4"
                      title="Current employee"
                      name="Current employee"
                      :rules="approveRules"
                    />
                  </div>
                </div>

                <div
                  v-if="currentEmployee === 'no'"
                  class="block"
                >
                  Deny this request and ask the vendor to submit an updated ACH form.
                </div>

                <div v-if="currentEmployee != 'no' && requestType != 'CancelEnrollment'">
                  <div class="block">
                    <div class="field">
                      <div class="top-align label radio-desc required">
                        Click the button below to view and verify the vendor's banking information.
                      </div>

                      <div class="block">
                        <div class="control">
                          <button
                            class="button is-primary"
                            :class="{ 'is-loading': bankInfoIsLoading }"
                            @click.prevent="viewBankInfo"
                          >
                            View bank information
                          </button>
                        </div>
                      </div>

                      <div
                        v-if="confirmationValue === 'click button above to enter bank info'"
                        class="icon-ilb"
                      >
                        <span class="icon has-text-warning">
                          <i class="fas fa-circle"></i>
                        </span>
                      </div>

                      <div
                        v-if="confirmationValue == 'confirmed'"
                        class="icon-ilb"
                      >
                        <span class="icon has-text-success">
                          <i class="fas fa-check-circle"></i>
                        </span>
                      </div>

                      <div
                        v-if="confirmationValue == 'not confirmed'"
                        class="icon-ilb"
                      >
                        <span class="icon has-text-danger">
                          <i class="fas fa-times-circle"></i>
                        </span>
                      </div>

                      <div class="box-ilb">
                        <vee-disabled-text-field
                          id="fname"
                          v-model="confirmationValue"
                          class="disabled-text-field"
                          title="disabled box"
                          name="Bank Info Confirmed"
                          :rules="bankApproveRules"
                        >
                        <!-- @changedValue="disabledChange(validation)" -->
                        </vee-disabled-text-field>
                      </div>
                    </div>

                    <div
                      v-if="bankVerified == false"
                      class="block"
                    >
                      Deny this request and ask the vendor to submit an updated ACH form.
                    </div>
                  </div>


                  <div v-if="bankVerified != false">
                    <div class="block">
                      Who did you speak with to verify this information?
                    </div>

                    <!-- <div>
                      <div
                        v-if="!isMobile"
                        class="textbox-label-field column is-3"
                      >
                        Name
                      </div>
                      <div class="column is-9"> -->
                        <div class="field">
                          <vee-textbox
                            v-model="verifiersName"
                            label="Verifier's name"
                            name="Verifier's name"
                            placeholder="Enter the name of the vendor contact that you spoke to"
                            :rules="approveRules"
                            required
                          />
                        </div>
                      <!-- </div>
                    </div> -->

                    <!-- <div class="columns">
                      <div
                        v-if="!isMobile"
                        class="textbox-label-field column is-3"
                      >
                        Position
                      </div>
                      <div class="column is-9"> -->
                        <div class="field">
                          <vee-textbox
                            v-model="verifiersPosition"
                            label="Verifier's position"
                            name="Verifier's position"
                            placeholder="Enter the contact's job title"
                            :rules="approveRules"
                            required
                          />
                        </div>
                      <!-- </div>
                    </div> -->

                    <!-- <div class="columns">
                      <div
                        v-if="!isMobile"
                        class="textbox-label-field column is-3"
                      >
                        Phone number
                      </div>
                      <div class="column is-9"> -->
                        <div class="field">
                          <vee-textbox
                            v-model="verifiersPhoneNumber"
                            label="Verifier's phone number"
                            name="Verifier's phone number"
                            placeholder="Enter the phone number you used for the contact"
                            :rules="'validPhone|' + approveRules"
                            required
                            @keyup="formatPhone"
                          />
                        </div>
                      <!-- </div>
                    </div> -->

                    <!-- <div class="columns">
                      <div
                        v-if="!isMobile"
                        class="textbox-label-field column is-3"
                      >
                        Date of call
                      </div>

                      <div class="column is-9"> -->
                        <date-picker
                          v-model="dateOfVerification"
                          :model-config="modelConfig"
                          class="inline-block h-full"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="columns is-mobile">
                              <div class="column is-2">
                                <div class="control margin-top-sm">
                                  <button
                                    class="button is-primary"
                                    @click.prevent="togglePopover()"
                                  >
                                    <span class="icon is-marginless">
                                      <i
                                        class="fas fa-calendar-alt"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </button>
                                </div>
                              </div>

                              <div class="column is-10">
                                <vee-textbox
                                  v-model="datePickerValue"
                                  label="Date of verification call"
                                  name="Date of verification call"
                                  placeholder="Enter the date that you spoke to the contact"
                                  :rules="approveRules"
                                  required
                                  readonly
                                />
                              </div>

                            </div>
                          </template>
                        </date-picker>
                      <!-- </div>
                    </div> -->
                  </div> <!-- end of v-if bankVerified != 'no' -->
                </div> <!-- end of v-if currentEmployee != 'no' -->
              </div> <!-- end of v-if telephoneVendor != 'no' -->
            </div> <!-- end of v-if matchesFAMIS != 'no'  -->
          </div> <!-- end of v-if namesMatch != 'no'  -->
        </div> <!-- end of v-if inDepartment != 'no' -->

        <div v-if="inDepartment != 'no'">
          <hr>

          <div
            v-if="allowApprove"
            class="block"
          >
            By clicking approve, I certify that I have confirmed that these payment instructions are correct by following the instructions above.
          </div>

          <div
            class="field columns"
          >
            <div class="column is-4">
              <vee-radio
                v-model="approveOrDeny"
                :options="approveDenyOptions"
                numOfColumns="2"
                title="Approve Or Deny"
                name="Approve Or Deny"
                :rules="noSelectionRules"
              />
            </div>

            <div class="column is-8">
              <vee-text-area
                v-if="approveOrDeny === 'deny'"
                v-model="reason"
                label="Reason"
                name="Reason"
                placeholder="Why are you denying this request?"
                :rules="denyRules"
              />
            </div>
          </div>

          <div class="control margin-top-sm">
            <button
              class="button is-primary"
              :class="{ 'is-loading': submitIsLoading }"
              @click.prevent="submitForm(validation)"
            >
              Submit
            </button>
          </div>

        </div> <!-- end of v-if inDepartment != 'no' -->

      </input-form>

    </ValidationObserver>

    <hr>

    <audit-trail />

    <modal
      ref="show-bank-info"
      class="is-medium"
      :settings="{ title: 'Bank Information Confirmation' }"
    >
      <bank-confirmation-form
        :achId="achId"
        @right="rightAnswer"
        @wrong="wrongAnswer"
      />
    </modal>

  </div>
</template>

<script>

import TransferComponent from '@/pages/ach-request/sections/TransferComponent';
import Overview from '@/pages/ach-request/sections/Overview';
import VendorSubmitted from '@/pages/ach-request/sections/VendorSubmitted';
import AuditTrail from '@/pages/ach-request/sections/AuditTrail';
import Modal from '@/components/common/Modal';
import DisabledTextField from '@/components/common/DisabledTextField';
import BankConfirmationForm from '@/pages/ach-request/BankConfirmationForm';

import { format, parseISO } from 'date-fns';

import {
  Textbox,
  TextArea,
  Checkbox,
  Radio,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';

import { required, email, ext, numeric, digits, is } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);
const VeeTextArea = withValidation(TextArea);
const VeeCheckbox = withValidation(Checkbox);
const VeeRadio = withValidation(Radio);
const VeeFile = withValidation(File);
const VeeDisabledTextField = withValidation(DisabledTextField);

export default {
  name: "AchRequestForm",
  components: {
    Overview,
    VendorSubmitted,
    AuditTrail,
    VeeTextbox,
    VeeTextArea,
    VeeRadio,
    VeeDisabledTextField,
    ValidationObserver,
    Modal,
    BankConfirmationForm,
    DatePicker,
    TransferComponent,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      approveOrDeny: null,
      submitIsLoading: false,
      bankInfoIsLoading: false,
      transferIsLoading: false,
      bankVerified: null,
      currentEmployee: null,
      inDepartment: null,
      namesMatch: null,
      matchesFAMIS: null,
      verifiersName: null,
      verifiersPosition: null,
      verifiersPhoneNumber: null,
      modelConfig: {
        type: 'string',
        // mask: 'MM/DD/YYYY',
      },
      dateOfVerification: null,
      reason: null,
      transferDropdownValue: null,
      telephoneVendor: null,
      booleanOptions: {
        'yes': 'Yes',
        'no': 'No',
      },
      // AchID: null,
      confirmationValue: 'click button above to enter bank info',
      infoDivs: {
        namesMatch: false,
      },
    };
  },
  computed: {
    datePickerValue() {
      let value;
      if (this.dateOfVerification) {
        value = format(parseISO(this.dateOfVerification), 'MM/dd/yyyy');
      } else {
        value = null;
      }
      return value;
    },
    approveDenyOptions() {
      let value = {};
      if (this.inDepartment != 'no' && this.namesMatch == 'yes' && this.matchesFAMIS != 'no' && this.telephoneVendor != 'no' && this.currentEmployee != 'no' && this.bankVerified != false) {
        value = {
          'approve': 'Approve',
          'deny': 'Deny',
        };
      } else {
        value = {
          'deny': 'Deny',
        };
      }
      return value;
    },
    allowApprove() {
      let value = true;
      if (this.namesMatch != 'yes' || this.matchesFAMIS == 'no' || this.telephoneVendor == 'no' || this.currentEmployee == 'no' || this.bankVerified == false) {
        value = false;
      }
      return value;
    },
    bankApproveRules() {
      let value;
      if (this.approveOrDeny !== 'deny') {
      // if (this.approveOrDeny === 'approve') {
        value = 'required|is:confirmed';
      } else {
        value = '';
      }
      return value;
    },
    approveRules() {
      let value;
      if (this.approveOrDeny !== 'deny') {
      // if (this.approveOrDeny === 'approve') {
        value = 'required';
      } else {
        value = '';
      }
      return value;
    },
    denyRules() {
      let value;
      if (this.approveOrDeny !== 'approve') {
      // if (this.approveOrDeny === 'deny') {
        value = 'required';
      } else {
        value = '';
      }
      return value;
    },
    noSelectionRules() {
      let value;
      if (this.approveOrDeny == null) {
        value = 'required';
      } else {
        value = '';
      }
      return value;
    },
    requestType() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.requestType;
      }
      return value;
    },
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    // console.log('ach-request Form.vue created is running, this.$route:', this.$route, 'this.$store.state.ach.achData.status:', this.$store.state.ach.achData.status);
    // this.AchID = this.$route.params.id;

    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('is', {
      ...is,
      message: 'The bank info was not entered or was not correct',
    });
    extend('validPhone', {
      validate: value => {
        const regex = new RegExp(/\(\d{3}\)\s\d{3}\s-\s\d{4}/);
        return regex.test(value);
      },
      message: (_, values) => 'phone number error',
    });
  },
  methods: {
    infoClick(divName) {
      // console.log('Form.vue infoClick, test:', divName, 'this.infoDivs[divName]:', this.infoDivs[divName]);
      if (Object.keys(this.infoDivs).includes(divName)) {
        this.infoDivs[divName] = !this.infoDivs[divName];
      }
    },
    reroute() {
      console.log('form reroute is running');
      this.$emit('achApiCallSuccess');
      this.$router.push({ name: 'ach-request-transfer', params: { 'id': this.achId }});
    },
    // async disabledChange(validation) {
    //   // console.log('ach-request/Form.vue disabledChange is running, validation:', validation);
    //   try {
    //     this.isValidatingName = true;
    //     await validation.validate("Bank Info Confirmed");
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     this.isValidatingName = false;
    //   }
    // },
    rightAnswer() {
      this.$emit('achApiCallSuccess');
      this.confirmationValue = 'confirmed';
      this.bankVerified = true;
      this.$refs['show-bank-info'].hide();
    },
    wrongAnswer() {
      this.$emit('achApiCallSuccess');
      this.confirmationValue = 'not confirmed';
      this.bankVerified = false;
      this.$refs['show-bank-info'].hide();
    },
    async viewBankInfo() {
      let query = {
        humanReadableId: this.achId,
      };
      const { data } = await this.$store.dispatch('ach/getAchBank', query);
      // console.log('Form.vue viewBankInfo is running, data:', data);
      this.eventType = 'view';
      this.$refs['show-bank-info'].show();
    },
    formatPhone (event) {
      if (event.keyCode !== 8) {
        this.verifiersPhoneNumber = this.$helpers.formatPhone(this.verifiersPhoneNumber);
      }
    },
    async submitForm(validation) {
      console.log('submitForm is running, validation:', validation);
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }
      this.submitIsLoading = true;
      if (this.approveOrDeny === 'deny') {
        let formData = {
          humanReadableId: this.achId,
          reason: this.reason,
        };
        const data = await this.$store.dispatch('ach/submitAchDenial', formData);
        if (data && data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Denial failed.');
        }
      } else {
        let formData = {
          humanReadableId: this.achId,
          verifiersName: this.verifiersName,
          verifiersPosition: this.verifiersPosition,
          verifiersPhoneNumber: this.verifiersPhoneNumber,
          verificationDate: this.dateOfVerification,
        };
        const data = await this.$store.dispatch('ach/submitAchApproval', formData);
        if (data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Approval failed.');
        }
      }
    },
  },
};

</script>

<style lang="scss">

.top-align {
  vertical-align: top;
  display: inline-block;
}

.icon-margin {
  margin-left: .5rem !important;
}

.icon-ilb {
  display: inline-block;
}

.box-ilb {
  display: inline-block;
  width: 500px;
}

.disabled-text-field {
  input {
    width: 300px !important;
  }
}

</style>
